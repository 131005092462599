// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  // DisabledInput,
  SimpleShowLayout,
  ReferenceInput,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  BooleanInput,
  DeleteButton,
  RichTextField,
  ImageField,
  ImageInput,
  DateTimeInput,
  SelectInput,
  NumberInput,
  SaveButton,
  Toolbar,
  FileField,
  FileInput, FormDataConsumer,
  useEditController
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
//@ts-ignore
import { GMapInput, GMapField } from '../../fields/GoogleMap';
import {useEffect, useState} from "react";

const FilterEntity = (props: any) => {
  return (<Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>);
};

export const ListEntity = (props: any) => (
  <List {...props} filter={props.filter} filters={<FilterEntity />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="value" />
      <TextField source="eurExchange" />
      <EditButton label="" />
    </Datagrid>
  </List>
);

const PostEditToolbar = (props: any) => (
    <Toolbar {...props} >
      <SaveButton />
    </Toolbar>
);

export const CreateEntity = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <NumberInput source="value" />
      <NumberInput source="eurExchange" />
    </SimpleForm>
  </Create>
);

export const EditEntity = (props: any) => {

  // const {record} = useEditController(props);
  // console.log('EditEntity', record)
  return (
      <Edit {...props} undoable={true}>
        <SimpleForm toolbar={<PostEditToolbar/>}>
          <TextField source="name" />
          <NumberInput source="value" />
          <NumberInput source="eurExchange" />
        </SimpleForm>
      </Edit>
  )
};
