
import 'firebase/firestore'
import axios from "axios";

export class MissingSubscriptionBucketService{

    // move from "transactions-missing" to "transactions" firestore collection by id
    static async move(id: string, missAddition: boolean = false) {
        await axios.get(`/api/mergeMissingSubscriptionBucket?id=${id}&missAddition=${missAddition}`);
    }
}
