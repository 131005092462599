// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {Datagrid, DateField, List, TextField, useRefresh} from "react-admin";
import "firebase/storage";
import "firebase/firestore";
import {MissingTransactionService} from "../transactions-missing/service";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Add from "@material-ui/icons/Add";
import Clear from "@material-ui/icons/Clear";
import {IconButton} from "@material-ui/core";
import {MissingSubscriptionBucketService} from "./service";



const IButton = (props: any) => {
    const {record} = props;
    const id = record.id;
    const disabled = !record.bucket;
    const onClick = () => {
        console.log({record})
        props.onClick && props.onClick(id);
    }
    return (<IconButton  {...props} disabled={disabled} onClick={ onClick } />)
}


export const ListEntity = (props: any) => {
    const refresh = useRefresh();

    const move = async (id: string) => {
        await MissingSubscriptionBucketService.move(id, false);
        refresh()
    }

    const moveWithoutAddition = async (id: string) => {
        await MissingSubscriptionBucketService.move(id, true);
        refresh()
    }

    return (
        (
            <List {...props}
            >
                <Datagrid>
                    <TextField source="id" title="ID"/>
                    <DateField showTime label={'Payment date'} source="date"
                               title="Created At"/>
                    <TextField source="eurAmount" title="Amount"/>
                    <TextField source="bucket" title="Amount"/>
                    <IButton onClick={move} color="primary" aria-label="upload picture"
                             title={'Add transaction with money addition'} component="label">
                        <AttachMoney/>
                    </IButton>
                    <IButton disabled={true} onClick={moveWithoutAddition} color="primary" aria-label="upload picture"
                             title={'Add transaction without money addition'} component="label">
                        <Add/>
                    </IButton>
                </Datagrid>
            </List>

        )
    )
};
