import {doc, getDoc, getFirestore} from "firebase/firestore";


const getFirestoreDocument = async (path: string) => {
    const firestore = getFirestore();
    const docRef = doc(firestore, path);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
        return {...snapshot.data(), id: snapshot.id}
    } else {
        return undefined;
    }
}

export default getFirestoreDocument;