// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
    Datagrid,
    DeleteButton,
    Edit,
    EditButton,
    Filter,
    List,
    NumberInput,
    SimpleForm,
    TextField,
    DateInput,
    DateField,
    SelectArrayInput,
    ReferenceArrayInput,
    TextInput, FormDataConsumer
} from "react-admin";
//@ts-ignore
import {GMapField, GMapInput} from '../../fields/GoogleMap';
import CreateBucket from '../buckets/components/CreateBucket'

const FilterEntity = (props: any) => {
    return (<Filter {...props}>
        <TextInput label="Search" source="name" alwaysOn/>
    </Filter>);
};

export const ListEntity = (props: any) => (
    <List {...props} filter={props.filter} filters={<FilterEntity/>}>
        <Datagrid>
            <TextField source="title"/>
            <TextField source="name"/>
            <TextField source="value"/>
            <TextField source="goal"/>
            <EditButton label=""/>
            <DeleteButton label=""/>
        </Datagrid>
    </List>
);


export const EditEntity = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="name"/>
            <DateInput disabled label="Last updated by admin" source="lastupdate" defaultValue={new Date()} />
            <TextInput source="title"/>
            <NumberInput source="goal"/>
            <NumberInput source="value"/>
            <ReferenceArrayInput reference="crypto_currencies" source="cryptoCurrencies"  filterToQuery={(id: string) =>  ({id})}>
                <SelectArrayInput fullWidth optionText="id"/>
            </ReferenceArrayInput>
            <FormDataConsumer>
                {
                    ({formData, ...rest}: any) => {
                        console.log({formData})
                        const selectedBuckets = formData.cryptoCurrencies || []
                        return selectedBuckets.length > 0 ?
                            (
                                <div className={'bucket-address-wrapper'}>
                                    {
                                        selectedBuckets.map(
                                            (bucket: string) =>
                                                (<TextInput required className={'full-width'} label={`${bucket} address`} fullWidth source={`crypto.${bucket}`} {...rest}/>)
                                        )
                                    }
                                </div>
                            ) :
                            null
                    }}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
);

export const CreateEntity = CreateBucket
