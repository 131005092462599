// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {Datagrid, DateField, DeleteButton, List, TextField} from "react-admin";
import {IconButton} from "@material-ui/core";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Add from "@material-ui/icons/Add";
import Clear from "@material-ui/icons/Clear";
import "firebase/storage";
import "firebase/firestore";
import {MissingTransactionService} from "./service";
import { useRefresh } from "react-admin";



const IButton = (props: any) => {
    const {record} = props;
    const id = record.id;
    const onClick = () => {
        console.log({record})
        props.onClick && props.onClick(id);
    }
    return (<IconButton  {...props} onClick={ onClick } />)
}



export const ListEntity = (props: any) => {
    const refresh = useRefresh();

    const move = async (id: string) => {
        await MissingTransactionService.move(id);
        refresh()
    }

    const moveWithoutAddition = async (id: string) => {
        await MissingTransactionService.move(id, false);
        refresh()
    }

    const skip = async (id: string) => {
        await MissingTransactionService.skip(id);
        refresh()
    }

    return (
        (
            <List {...props}
            >
                <Datagrid>
                    <TextField source="id" title="ID"/>
                    <DateField showTime label={'Payment date'} source="metadata.transaction_initiation_date" title="Created At"/>
                    <TextField source="eurAmount" title="Amount"/>
                    <TextField source="bucket" title="Amount"/>
                    <IButton onClick={move} color="primary" aria-label="upload picture" title={'Add transaction with money addition'} component="label">
                        <AttachMoney />
                    </IButton>
                    <IButton onClick={moveWithoutAddition}  color="primary" aria-label="upload picture" title={'Add transaction without money addition'} component="label">
                        <Add />
                    </IButton>
                    <IButton onClick={skip} color="primary" aria-label="upload picture" title={'Skip adding this transactions'} component="label">
                        <Clear />
                    </IButton>
                </Datagrid>
            </List>

        )
    )
};