// in src/posts.js
import * as React from "react";
import {
    Button
} from "@material-ui/core";
// tslint:disable-next-line:no-var-requires
import {
    Datagrid,
    AutocompleteInput,
    BooleanInput,
    BooleanField,
    TopToolbar,
    CreateButton,
    ExportButton,
    List,
    Create,
    Edit,
    Filter,
    ReferenceInput,
    ReferenceField,
    SimpleForm,
    TextField,
    TextInput,
    EditButton,
    DeleteButton,
    SelectInput,
    NumberInput,
} from "react-admin";
//@ts-ignore
import {GMapInput, GMapField} from '../../fields/GoogleMap';

const FilterEntity = (props: any) => {
    return (<Filter {...props}>
        <ReferenceInput label="Bucket" source="bucket" reference="buckets"
                        filterToQuery={(fullName: string) => ({fullName})}>
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
    </Filter>);
};


const ListActions = (props: any) => (
    <TopToolbar>
        <CreateButton/>
        <ExportButton maxResults={100000}/>
    </TopToolbar>
);

function fallbackCopyTextToClipboard(text: string) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}

function copyTextToClipboard(text: string) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function () {
        console.log('Async: Copying to clipboard was successful!');
    }, function (err) {
        console.error('Async: Could not copy text: ', err);
    });
}


const CopyButton = (props: any) => {
    const [copied, setCopied] = React.useState(false);
    return (
        <Button variant={copied ? 'text' : 'outlined'} onClick={async (e) => {
            await copyTextToClipboard(`${process.env.REACT_APP_LINKS_HOST}${props.record.id}`);
            setCopied(true);
        }}>Copy Link</Button>
    )
};

export const ListEntity = (props: any) => (
    <List {...props} filter={props.filter} actions={<ListActions/>} filters={<FilterEntity/>}>
        <Datagrid>
            <ReferenceField label="Bucket" source="bucket" reference="buckets">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="currency"/>
            <TextField source="amount"/>
            <TextField source="eurAmount"/>
            <TextField source="tipsAmount"/>
            <BooleanField source="subscription"/>
            <CopyButton/>
            <EditButton label=""/>
            <DeleteButton label=""/>
        </Datagrid>
    </List>
);


const Form = (props: any) => {
    return (
        <SimpleForm {...props}>
            <ReferenceInput label="Bucket" source="bucket" reference="buckets"
                            filterToQuery={(name: string) => ({name})}>
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>
            <TextInput source="source" required/>
            <TextInput source="name" required/>
            <TextInput source="description" required/>
            <TextInput source="success_url" required defaultValue={'https://bysol.org/spasibo?bysoldId=links'}/>
            <TextInput source="success_url_sub" required defaultValue={'https://bysol.org/spasibo?bysoldId=links'}/>
            <TextInput source="cancel_url" required defaultValue={'https://bysol.org'}/>
            <AutocompleteInput source="lng" default={'ru'} choices={[
                {id: 'ru', name: 'Русский'},
                {id: 'en', name: 'Английский'},
            ]}/>
            <AutocompleteInput source="currency" choices={[
                {id: 'eur', name: 'Евро €'},
                {id: 'usd', name: 'Доллары US$'},
                {id: 'pln', name: 'Польский злотый zł'},
                {id: 'czk', name: 'Чешские кроны Kč'},
                {id: 'uah', name: 'Украинские гривны ₴'},
                {id: 'gbp', name: 'Фунты Стерлингов £'},
                {id: 'chf', name: 'Швейцарские франки ₣'},
                {id: 'aud', name: 'Австралийские доллары AUD'},
                {id: 'cad', name: 'Канадские доллары CAD'},
                {id: 'rub', name: 'Российские рубли ₽'},
            ]} required/>
            <NumberInput source="amount" required/>

            <BooleanInput source="subscription" defaultValue={false}/>
            <BooleanInput source="closed" defaultValue={false}/>
            <NumberInput source="tipsAmount" defaultValue={0}/>

        </SimpleForm>
    )
}


export const CreateEntity = (props: any) => (
    <Create {...props}>
        <Form {...props}/>
    </Create>
);

export const EditEntity = (props: any) => (
    <Edit {...props}>
        <Form {...props}/>
    </Edit>
);
