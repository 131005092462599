import * as React from "react";
import {Admin, Resource} from "react-admin";
//@ts-ignore
import {FirebaseAuthProvider, FirebaseDataProvider, RAFirebaseOptions} from "react-admin-firebase";
import crypto from "./resouces/crypto";
import buckets from "./resouces/buckets";
import transactions from "./resouces/transactions";
import sources from "./resouces/sources";
import links from "./resouces/links";
import paypalSync from "./resouces/paypal-report-uploads";
import transactionMissing from "./resouces/transactions-missing";
import subscriptionBucketMissing from "./resouces/subscriptions-bucket-missing";
import {
    InsertLink,
    AccountBalanceWallet,
    ShoppingBasket, AttachMoney, CompareArrows
} from '@material-ui/icons';
// import useAdminConfig from "./hooks/useAdminConfig";
import styled from "styled-components";
import FacebookCircularProgress from "./components/FacebookCircularProgress";
import './styles.css'
import { Dashboard } from './components/Dashboard';
import { Route } from 'react-router-dom';
import { MyLayout } from './components/Menu';

const options: RAFirebaseOptions = {
    logging: true,
    rootRef: "/",
    softDelete: true,
    persistence: "local",
    lazyLoading: {
        enabled: false,
    },
    watch: ['transaction-missing'],
};

const dataProvider = (config: any) => {
    const dp = FirebaseDataProvider(config, options);
    // tryToConnectToFirestoreEmulator();
    return dp;
};
const authProvider = (config: any) => FirebaseAuthProvider(config, options);

const PreloaderWrapper = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
`
const config = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG || '{}')
console.log("process.env.REACT_APP_FIREBASE_CONFIG: ", {config})
const App = () => {
    // const config = useAdminConfig();
    return (
        <Admin
            layout={MyLayout}
            dataProvider={dataProvider(config)}
            authProvider={authProvider(config)}
            customRoutes={[
                // @ts-ignore
                <Route path="/general" component={Dashboard} />,
            ]}
        >
            <Resource icon={ShoppingBasket} name="buckets" {...buckets}/>
            <Resource icon={CompareArrows} name="transactions" {...transactions}/>
            <Resource icon={AttachMoney} name="crypto_currencies" {...crypto}/>
            <Resource icon={InsertLink} name="links" {...links}/>
            <Resource icon={AccountBalanceWallet} name="sources" {...sources}/>
            <Resource icon={AccountBalanceWallet} name="paypal-report-uploads" options={{label: 'Paypal uploads'}} {...paypalSync}/>
            <Resource icon={AccountBalanceWallet} name="transactions-missing" options={{label: 'Missing transactions'}} {...transactionMissing}/>
            <Resource icon={AccountBalanceWallet} name="subscriptions-bucket-missing" options={{label: 'Paypal uploads'}} {...subscriptionBucketMissing}/>
        </Admin>
    )
}

export default App;
