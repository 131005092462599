import * as React from 'react';
import {Layout, Menu, MenuItemLink} from 'react-admin';
import {
    AttachMoney,
    CompareArrows,
    InsertLink,
    Public,
    ShoppingBasket,
    CloudUpload,
    Refresh,
} from '@material-ui/icons';

export const MyMenu = () => {
    return (
        <Menu>
            <MenuItemLink to="/general" primaryText="General" leftIcon={<Public/>}/>
            <MenuItemLink to="/buckets" primaryText="Buckets" leftIcon={<ShoppingBasket/>}/>
            <MenuItemLink to="/transactions" primaryText="Transactions" leftIcon={<CompareArrows/>}/>
            <MenuItemLink to="/crypto_currencies" primaryText="Crypto currencies" leftIcon={<AttachMoney/>}/>
            <MenuItemLink to="/links" primaryText="Links" leftIcon={<InsertLink/>} name="links"/>
            {/*<MenuItemLink to="/sources" primaryText="Sources" leftIcon={<AccountBalanceWallet/>}/>*/}
            <MenuItemLink to="/paypal-report-uploads" primaryText="Paypal uploads" leftIcon={<CloudUpload/>}/>
            <MenuItemLink to="/transactions-missing" primaryText="Missing transactions"
                          leftIcon={<Refresh/>}/>
            <MenuItemLink to="/subscriptions-bucket-missing" primaryText="Fix bucket"
                          leftIcon={<Refresh/>}/>
        </Menu>
    );
};

export const MyLayout = (props: any) => <Layout {...props} menu={MyMenu}/>;
