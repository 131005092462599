// in src/posts.js
import * as React from "react";
import {Box, Drawer, Typography} from '@material-ui/core';
import {Route} from 'react-router-dom';
// tslint:disable-next-line:no-var-requires
import {
    Create,
    CreateButton,
    Datagrid,
    DateField,
    DeleteButton,
    FileField,
    FileInput,
    List,
    SimpleForm,
    TextField,
    TopToolbar
} from "react-admin";
import firebase from "firebase/compat/app";
import "firebase/storage";
import "firebase/firestore";
import {useAuthProvider, useNotify, useRedirect} from "ra-core";
import {FileUtils} from "../../utils/FileUtils";
import {CSV} from "../../utils/CSV";
import axios from "axios";

const ListActions = (props: any) => {
    return (
        <TopToolbar>
            <CreateButton basePath={props.basePath}/>
        </TopToolbar>
    )
};


export const ListEntity = (props: any) => (
    <React.Fragment>
        {/*
        //@ts-ignore*/}
        <Box textAlign="center" m={1}>
            <a target={"__blank"}
               href={'https://www.notion.so/How-to-download-report-from-Paypal-0634f363420d49bd899ae70d3fbe474b'}>
                How to download report from paypal
            </a>
        </Box>
        <List {...props}
              empty={<Empty/>}
              actions={<ListActions/>}
        >
            <Datagrid>
                <DateField showTime source="createdAt" title="Uploaded"/>
                <TextField source="createdBy" title="Created"/>
                <DateField showTime source="first.date" title="First record date"/>
                <DateField showTime source="last.date" title="Last record date"/>
                <DeleteButton label=""/>
            </Datagrid>
        </List>
        {/*//@ts-ignore*/}
        <Route
            path="/paypal-report-uploads/create"
            render={() => (
                <Drawer anchor={'right'} open>
                    <CreateEntity {...props} />
                </Drawer>
            )}
        />
    </React.Fragment>

);

const Empty = () => (
    <React.Fragment>
        {/*
        //@ts-ignore*/}
        <Box textAlign="center" m={1}>
            <Typography variant="h4" paragraph>
                No reports uploaded
            </Typography>
            <CreateButton basePath={'/paypal-report-uploads'}/>
        </Box>
    </React.Fragment>
);


const checkIfRightFile = (r: any) => {
    console.log('checkIfRightFile', r);
    return (
        r.hasOwnProperty('Transaction ID')
        && r.hasOwnProperty('Custom Number')
        && r.hasOwnProperty('Reference Txn ID')
        && r.hasOwnProperty('Date')
        && r.hasOwnProperty('Time')
    )
}

export const toUTCFormat = (r: any) => {
    // `14/10/2021 05:29:31 PDT` -> `2021-10-14T19:20:30.45-07:00`
    const {'Date': d, "Time": t} = r;
    console.log({r, d, t})
    const [day, month, year] = d.split('/');
    return new Date(`${year}-${month}-${day}T${t}.000Z`)

}

export const CreateEntity = (props: any) => {
    const notify = useNotify();
    const redirect = useRedirect()
    const authProvider = useAuthProvider();
    return (
        <Create {...props}>
            <SimpleForm
                save={
                    async (data: any) => {
                        // console.log('create entity: >>>> data', data.report, {obj});
                        const storage = firebase.storage().ref('/paypal/paypal-active-transaction-report.csv')
                        const file = data.report.rawFile as File;
                        const csvString = await FileUtils.readTextFile(file);
                        const obj = CSV.parse(csvString);


                        const firstRecord = obj[0]

                        if (!checkIfRightFile(firstRecord)) {
                            notify('wrong file format')
                        } else {
                            try{
                                const lastRecord = obj[obj.length - 2];
                                const fileData = await file.arrayBuffer();
                                await storage.put(fileData, {contentType: 'text/csv'});
                                const user = await authProvider.getAuthUser();
                                const firstDate = toUTCFormat(firstRecord);
                                const lastDate = toUTCFormat(lastRecord);
                                console.log({firstDate, lastDate})
                                await firebase.firestore().collection(props.basePath).add({
                                    createdAt: new Date(),
                                    createdBy: user.email,
                                    first: {
                                        date: firstDate,
                                        name: firstRecord.Name,
                                    },
                                    last: {
                                        date: lastDate,
                                        name: lastRecord.Name,
                                    },
                                })

                                await axios.get('/api/parsePaypalSubscriptions')
                                await notify('File uploaded');
                                await redirect('/paypal-report-uploads');
                            }catch(e){
                                const err = e as Error;
                                await notify(`Error: ${err.message}`);
                            }

                        }


                    }}
            >
                <FileInput accept={['.csv']} source="report">
                    <FileField source="src" title="title"/>
                </FileInput>
            </SimpleForm>
        </Create>
    )
};
