import firebase from "firebase/compat/app";
import "firebase/compat/remote-config";
import {useEffect, useState} from "react";

// hook
export const useRemoteConfigVariable = (name:string) => {
    const [value, setValue] = useState<string>('')
    useEffect(() => {
        const remoteConfig = firebase.remoteConfig()
        remoteConfig.fetchAndActivate().then(() => {
            const value = remoteConfig.getString(name)
            setValue(value)
        })
    }, [])
    return value
}

