import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import getFirestoreDocument from '../utils/firebase/getFirestoreDocument';
import {Card, CardContent, CircularProgress, Typography} from '@material-ui/core';
import {
    Button
} from "@mui/material";
import axios from "axios";
// import Button from "@material-ui/core/Button";
import {downloadCSV} from "../utils/downloadCSV";
import {useRemoteConfigVariable} from "../utils/remote-config";
import {doc} from "firebase/firestore";

//hook /api/subscriptions
const useSubscriptions = () => {
    const [data, setData] = useState<null | string>(null);
    useEffect(() => {
        axios.get('/api/subscriptions').then(({data}) => setData(data ? data.subscriptions : 0))
    }, [])
    return data;
}


export const useDownloadSubscriptions = () => {
    const token = useRemoteConfigVariable('api_token');
    return useCallback(() => {
        downloadCSV(`/api/download-subscriptions?token=${token}`, `subscriptions-${new Date().toISOString()}`)
    }, [token]);
}

export const Dashboard: React.FC = () => {
    const [data, setData] = useState<any>(null);
    const subscriptions = useSubscriptions();
    const downloadSubscriptions = useDownloadSubscriptions();
    useEffect(() => {
        getFirestoreDocument('settings/counters').then(document => setData(document || {}))
    }, [])

    // const params = {data};
    // @ts-ignore
    return data ? (
        <div>
            <Card style={{margin: 10}}>
                <CardContent>
                    <Typography variant="subtitle1">
                        Собрано за все время
                    </Typography>
                    <Typography variant="h2">
                        {data.total.collected.toFixed(2)}
                    </Typography>
                </CardContent>
            </Card>
            <Card style={{margin: 10}}>
                <CardContent>
                    <Typography variant="subtitle1">
                        Всего транзакций
                    </Typography>
                    <Typography variant="h2">
                        {data.total.count}
                    </Typography>
                </CardContent>
            </Card>
            <Card style={{margin: 10}}>
                <CardContent>
                    <Typography variant="subtitle1">
                        Подписок
                    </Typography>
                    <Typography variant="h2">
                        {subscriptions === null ? <CircularProgress/> : subscriptions}
                    </Typography>
                    {/*  Download button */}
                    <Button onClick={downloadSubscriptions} variant="contained" color="primary">
                        Скачать
                    </Button>
                </CardContent>
            </Card>
        </div>


    ) : <CircularProgress/>;
}
