import {CreateEntity, EditEntity, ListEntity} from "./entities";
import * as React from "react";

export const buckets = {
    list: ListEntity,
    create: CreateEntity,
    edit: EditEntity,
}


export default buckets
