import {CreateEntity, EditEntity, ListEntity} from "./entities";
import {Resource} from "react-admin";
import * as React from "react";

export const paypalSync = {
    list: ListEntity,
    create: CreateEntity,
    edit: EditEntity,
}


export default paypalSync
