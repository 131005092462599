import React from 'react'
import {
    Create, DateInput,
    FormDataConsumer,
    NumberInput,
    ReferenceArrayInput, SelectArrayInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useNotify,
    useRedirect,
    useRefresh
} from 'react-admin';
import SaveButton from "./SaveCustomWorkflowButton";
import updateFirestoreDocument from "../../../utils/firebase/updateFirestoreDocument";

interface Bucket {
    id: string;
    name: string;
    goal: number;
    value: number;
    cryptoCurrencies: string[];
}

export const CreateBucket = (props: any) => {
    const transform = (data: Bucket) => ({
        ...data,
        id: data.name,
    });
    return (
        <Create undoable={false} transform={transform} {...props}>
            <SimpleForm>
                <TextInput source="title"/>
                <DateInput disabled label="Last updated by admin" source="lastupdate" defaultValue={new Date()} />
                <TextInput source="name" required/>
                <NumberInput source="goal"/>
                <NumberInput source="value" defaultValue={0}/>
                <ReferenceArrayInput reference="crypto_currencies" source="cryptoCurrencies"
                                     filterToQuery={(id: string) => ({id})}>
                    <SelectArrayInput fullWidth optionText="id"/>
                </ReferenceArrayInput>
                <FormDataConsumer>
                    {
                        ({formData, ...rest}: any) => {
                            console.log({formData})
                            const selectedBuckets = formData.cryptoCurrencies || []
                            return selectedBuckets.length > 0 ?
                                selectedBuckets.map(
                                    (bucket: string) =>
                                        (<TextInput label={bucket} source={`crypto.${bucket}`} {...rest}/>)
                                ) :
                                null
                        }}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
};

export default CreateBucket;
