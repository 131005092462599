import firebase from 'firebase/compat/app';
import 'firebase/firestore'

export class MissingTransactionService{

    // move from "transactions-missing" to "transactions" firestore collection by id
    static async move(id: string, missAddition: boolean = false) {
        const missingTransactionRef = firebase.firestore().collection('transactions-missing').doc(id);
        const missingTransaction: any = await missingTransactionRef.get().then(doc => doc.data());
        await firebase.firestore().collection('transactions').doc(id).set({...missingTransaction, missAddition}, {merge: true});
        await missingTransactionRef.delete();
    }

    static skip(id: string) {
        return firebase.firestore().collection('transactions-missing').doc(id).set({deleted: true}, {merge: true});
    }
}
