
export class CSV {
    static parseLineToParameters = (line: string) => line.split(/(?:,|\n|^)("(?:(?:"")*[^"]*)*"|[^",\n]*|(?:\n|$))/g).filter((value, i) => i % 2 === 1);
    static parse = (data: string) => {
        // get parameters from the first line of the csv
        const firstRow = data.split('\n')[0];
        const paramNames = CSV.parseLineToParameters(firstRow)
        const headers = paramNames
            .map(header => header
                .replace(/^"|"$/g, '')
                .replace(/\//g, '-')
            );

        const rows = data.split('\n').slice(1);
        return rows.map(row => {
            const values = CSV.parseLineToParameters(row);
            // console.log({values})
            return values.reduce((acc, value, index) => {
                // console.log(`\n \t${headers[index]}: \t\t\t ${value}`);
                acc[headers[index]] = value.replace(/^"|"$/g, '');
                return acc;
            }, {} as any);
        });
    }
}