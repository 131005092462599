export const downloadCSV = (url: string, filename: string) => {
    fetch(url) // Replace with the actual URL to download the CSV file
        .then(response => response.text())
        .then(csvData => {
            const downloadLink = document.createElement('a');
            const blob = new Blob([csvData], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            downloadLink.href = url;
            downloadLink.download = `${filename}.csv`;
            downloadLink.click();
            URL.revokeObjectURL(url);
        })
        .catch(error => {
            console.error('Error downloading CSV:', error);
        });
};
