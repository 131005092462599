// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  BooleanField,
  Datagrid,
  DateField,
  TopToolbar,
  CreateButton,
  ExportButton,
  List,
  Create,
  Edit,
  Filter,
    AutocompleteInput,
  DateInput,
  ReferenceInput,
  ReferenceField,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  DeleteButton,
  DateTimeInput,
  SelectInput,
  NullableBooleanInput
} from "react-admin";
//@ts-ignore
import { GMapInput, GMapField } from '../../fields/GoogleMap';

const FilterEntity = (props: any) => {
  return (<Filter {...props}>
    {/*<DateInput source="date_gte" label="Released after" alwaysOn />*/}
    {/*<DateInput source="date_lte" label="Released before" alwaysOn />*/}
    <ReferenceInput label="PaymentSource" source="paymentSourceType" reference="sources" alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Bucket" source="bucket" reference="buckets" alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>);
};


const ListActions = (props: any) => (
    <TopToolbar>
      <CreateButton/>
      <ExportButton maxResults={100000}/>
    </TopToolbar>
);

export const ListEntity = (props: any) => (
  <List {...props} filter={props.filter} actions={<ListActions/>} filters={<FilterEntity />}>
    <Datagrid>
      <DateField source="date" locales="ru"/>
      <ReferenceField label="Bucket" source="bucket" reference="buckets">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="source" />
      <TextField source="eurAmount" />
      <TextField source="currency" />
      <TextField source="amount" />
      <EditButton label="" />
      <DeleteButton label="" />
    </Datagrid>
  </List>
);


export const CreateEntity = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="id" required></TextInput>
      <DateTimeInput source="date" defaultValue={new Date()}/>
      <ReferenceInput label="Bucket" source="bucket" reference="buckets">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="source" />
      <TextInput source="currency" defaultValue={'eur'} />
      <TextInput source="amount" />
      <TextInput source="eurAmount" required/>
    </SimpleForm>
  </Create>
);

export const EditEntity = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <DateTimeInput disabled source="date" />
      <ReferenceInput label="Bucket" source="bucket" reference="buckets">
        <SelectInput disabled source="name" />
      </ReferenceInput>
      <TextInput disabled source="source" />
      <TextInput disabled source="currency" />
      <TextInput disabled source="amount" />
      <TextInput disabled  source="eurAmount" />

    </SimpleForm>
  </Edit>
);
