// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
    Datagrid,
    BooleanInput,
    NumberInput,
    BooleanField,
    DeleteButton,
    Edit,
    EditButton,
    Filter,
    ImageField,
    ImageInput,
    Create,
    List,
    SimpleForm,
    TextField,
    TextInput
} from "react-admin";
//@ts-ignore
import {GMapField, GMapInput} from '../../fields/GoogleMap';

const FilterEntity = (props: any) => {
    return (<Filter {...props}>
        <TextInput label="Search" source="name" alwaysOn/>
    </Filter>);
};

export const ListEntity = (props: any) => (
    <List {...props} filter={props.filter} filters={<FilterEntity/>}>
        <Datagrid>
            <BooleanField source={'enabled'} />
            <TextField source="title"/>
            <ImageField className={'image-crypto'} source="icon.src" title="title"/>
            <TextField source="sort"/>
            <EditButton label=""/>
            <DeleteButton label=""/>
        </Datagrid>
    </List>
);


export const EditEntity = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput required source="title"/>
            <BooleanInput source={'enabled'} />
            <ImageInput required source="icon" label="Related pictures" accept="image/*">
                <ImageField source="src" title="title"/>
            </ImageInput>
            <NumberInput source={'sort'} defaultValue={0}/>
        </SimpleForm>
    </Edit>
);

export const CreateEntity = (props: any) => (
    <Create transform={(p: any) => ({
        ...p,
        id: p.title.replace(/\W/g,'_').toLowerCase()
    })} {...props}>
        <SimpleForm>
            <TextInput required source="title"/>
            <BooleanInput source={'enabled'} defaultValue={true} />
            <ImageInput required source="icon" label="Related pictures" accept="image/*">
                <ImageField source="src" title="title"/>
            </ImageInput>
            <NumberInput source={'sort'} defaultValue={0}/>
        </SimpleForm>
    </Create>
);
